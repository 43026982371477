<script lang="jsx">
import Cookies from 'js-cookie'
import alias from '@alias'
import { h, computed } from 'vue'
import kzLang from '@languages/kz.json'
import ruLang from '@languages/ru.json'
import { checkActiveLang, getActiveLang } from '@components/lang/langStore.js'

export default {
  props: {
    unWrap: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'span'
    },
    default: {
      type: String,
      default: 'ru'
    },
    name: {
      type: [String, Array],
      default: '',
      required: true
    },
    provideWrapper: {
      type: Boolean,
      default: false
    }
  },
  provide() {
    const langVariable = computed(() => this.variable.replace('<br/>', '\n'))
    return { langVariable }
  },
  data() {
    return {
      dict: {},
      defaultDict: {}
    }
  },
  watch: {
    lang() {
      this.setDict()
    }
  },
  created() {
    if (this.default.length > 0) {
      this.getLangPack(this.default).then(lang => {
        this.defaultDict = lang
      })
    }
  },
  mounted() {
    checkActiveLang()
    this.setDict()
  },
  methods: {
    setDict() {
      if (this.lang?.length > 0) {
        this.getLangPack(this.lang).then(lang => {
          this.dict = lang
        })
      }
    },
    getLangPack(lang) {
      return new Promise(resolve => {
        const langs = {
          ru: ruLang,
          kz: kzLang,
          en: {}
        }
        resolve(langs[lang])
      })
    }
  },
  computed: {
    variable() {
      if (Array.isArray(this.name)) {
        return this.name
          .filter(i => i in this.dict && this.dict[i].length > 0)
          .map(i => this.dict[i])
      }

      if (this.name in this.dict && this.dict[this.name].length > 0) {
        return this.dict[this.name]
      }
      if (this.name in this.defaultDict) {
        return this.defaultDict[this.name]
      }

      return this.name
    },
    lang: getActiveLang
  },
  render() {
    if (this.provideWrapper) {
      return this.$slots.default?.() ?? null
    }
    if (this.unWrap) {
      return this.variable
    }
    return h(this.tag, { innerHTML: this.variable })
  }
}
</script>
