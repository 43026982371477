import { reactive } from 'vue'
import { projectsList, extendProjectList } from '@api/index.js'

const store = reactive({
  projects: [],
  projectsRus: [],
  // projectsYears: ['2022','2021','2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012', '2011',
  // '2010', '2009', '2008', '2007', '2006'],
  projectsYears: {
    rus: ['2023', '2022', '2021', '2020', '2019'],
    kz: [
      '2024',
      '2023',
      '2022',
      '2021',
      '2020',
      '2019',
      '2018',
      '2017',
      '2016',
      '2015',
      '2014',
      '2013'
    ]
  },
  gotResponseFlag: false
})

export const projectActions = {
  getProject(year, region) {
    projectsList(year, 1000, region).then(obj => {
      region == 'rus' ? (store.projectsRus = obj) : (store.projects = obj)
    })
    // store.projectsYears = store.projectsYears.sort((a,b) => a > b ? -1 : 1)
  }
}

export const extendProjects = (year, range, region) => {
  extendProjectList(year, range, region).then(extension => {
    if (extension.length > 0) {
      if (region == 'rus') {
        store.projectsRus = store.projectsRus.concat(extension)
      } else {
        store.projects = store.projects.concat(extension)
      }
    }
  })
}

export const projectSetter = {
  setResponseFlag: value => (store.gotResponseFlag = value)
  // setList(list){
  //   // list.forEach(i=>{
  //   //   // const {year,fileName,id} = i
  //   //   // if(! (year in store.list)){
  //   //   //   store.list[year] = []
  //   //   // }
  //   //   // store.projects[year].push({year,fileName,id})
  //   // })
  //   // store.projectsYears = Object.keys(store.projects)
  // }
}

export const projectGetter = {
  projects: () => store.projects,
  projectsRus: () => store.projectsRus,
  projectsYears: () => store.projectsYears,
  gotResponseFlag: () => store.gotResponseFlag
}
